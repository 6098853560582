import { INFO_LEVEL } from '@glass/common/modules/tracking/constants/levels';
import { JPEG_FORMAT } from '@glass/common/modules/utils/fileFormats';

import { RESUMES_PREFIX } from '@glass/shared/modules/images/constants';
import { createOptionsSlug } from '@glass/shared/modules/images/imageOptionsSlug';
import tracking from '@glass/shared/modules/tracking';

const generateResumeImagePath = ({
  contentResumeSlug,
  template,
  jSlug,
  iSlug,
  seed,
  updatedAt,
} = {}) => {
  let options = {
    template,
  };

  if (updatedAt) {
    options.v = new Date(updatedAt).getTime();
  }

  if (process.env.NEXT_PUBLIC_LIMIT_RESUME_IMAGES) {
    options = {
      ...options,
      resume: contentResumeSlug || 'default',
    };
  } else if (contentResumeSlug) {
    options = {
      ...options,
      resume: contentResumeSlug,
    };
  } else if (seed) {
    options = {
      ...options,
      seed,
      jSlug,
      iSlug,
    };
  } else {
    throw new Error('invalid image params');
  }
  const optionsSlug = createOptionsSlug(options);

  const imagePath = `/${RESUMES_PREFIX}/${optionsSlug}.${JPEG_FORMAT}`;

  if (imagePath.match(new RegExp('u00'))) {
    tracking.exception(
      new Error(
        `generateResumeImagePath imagePath with bad characters : ${imagePath}, options: ${optionsSlug}`,
      ),
      null,
      { level: INFO_LEVEL },
    );
  }
  return imagePath;
};

export default generateResumeImagePath;
