import { useMemo } from 'react';

import generateResumeImagePath from '@glass/shared/modules/images/generateResumeImagePath';

const useResumeImagePath = ({
  contentResumeSlug,
  template,
  jSlug,
  iSlug,
  seed,
  updatedAt,
  generateDevImages,
}) =>
  useMemo(
    () =>
      generateResumeImagePath({
        contentResumeSlug,
        template,
        jSlug,
        iSlug,
        seed,
        updatedAt,
        generateDevImages,
      }),
    [jSlug, iSlug, seed, contentResumeSlug, template, updatedAt, generateDevImages],
  );

export default useResumeImagePath;
